import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  connect() {
   console.log("Pinned Note Controller Connected")
  }

  async pinNote(event) {
    event.preventDefault()

    document.querySelectorAll(".note-pin").forEach(element => {
      element.classList.remove("pinned")
    });

    const targetElement = event.currentTarget
    const request = new FetchRequest("patch", targetElement.dataset.url)
    
    await request.perform()

  }
}

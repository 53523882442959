import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import hotkeys from "hotkeys-js";

export default class extends Controller {
  static targets = [ "quickLookupModal" ]

  connect() {
    if(this.modal){
      this.modal.dispose()
    }
    this.modal = Modal.getOrCreateInstance(this.quickLookupModalTarget);
    this.quickLookupModalTarget.addEventListener("shown.bs.modal", (event) => {
      event.target.querySelector(".form-control").focus()
    })
    hotkeys("ctrl+l", () => this.toggleModal());
  }
  disconnect(){
    if(this.modal){
      this.quickLookupModalTarget.removeEventListener("shown.bs.modal", (event) => {
        event.target.querySelector(".form-control").focus()
      })
      this.modal.dispose()
    }
    hotkeys.unbind();
  }
  clickToOpen(event){
    event.preventDefault()
    this.toggleModal()
  }
  toggleModal(){
    this.modal.toggle()

  }
  resetForm(event) {
      this.element.reset()
  }
}

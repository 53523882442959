import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [ 'itemsField' ];
  static values = { url: String }

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "pagination-items"
  }

  disconnect() {
  }

  showItems(event) {
    let wrapper = event.target.closest("." + this.wrapperClass)
    Turbo.visit(this.urlValue+"?limit="+ wrapper.querySelector("input").value);
  }
}
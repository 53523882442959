import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.modal = Modal.getOrCreateInstance(this.modalTarget);
  }

  itemCreated(event) {
    event.preventDefault()
    let success = event.detail.success

    if(success) {
      this.modal.hide()
      event.target.reset()
    }
  }

  itemUpdated(event){
    event.preventDefault()
    let success = event.detail.success

    if(success){
      this.modal.hide()
      event.target.reset()
    }
  }

  newItem(event){
    this.modal.show()
  }

  editItem(event) {
    this.modal.show()
  }
}


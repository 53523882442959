import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import { Modal } from 'bootstrap'
import { FetchRequest } from '@rails/request.js'
window.Turbo = Turbo

export default class extends Controller {
  static targets = [ "viewModal", "handlingUnit" ]

  connect() {
    this.viewModal = Modal.getOrCreateInstance(this.viewModalTarget);
  }

  async showHandlingUnit(event) {
    const request = new FetchRequest("get", event.currentTarget.dataset.url, { responseKind: "turbo-stream" })
    const response = await request.perform()
    this.viewModal.show()
  }
}

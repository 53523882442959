import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }

  async setAccount(event) {
    event.preventDefault()
   
      let accountId = event.target.value
      let data = new FormData()
  
      data.append("account_id", accountId)
  
      const request = new FetchRequest("put", this.urlValue, { body: data }, { responseKind: "json" })
      const response = await request.perform()

      if (response.ok) {
        window.location = "/"
      }
  }
}
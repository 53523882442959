import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'disabledFields' ];

  connect() {
  }

  disconnect() {
  }

  resetAdvancedFields(event) {
    event.preventDefault();

    // Find all input and select elements within this controller's element
    const inputs = this.element.querySelectorAll("input, select");

    inputs.forEach(input => {
      if (input.type === "select-one") {
        input.selectedIndex = 0; // Reset select fields to first (blank) option
      } else if (input.type === "date" || input.type === "text") {
        input.value = ""; // Clear text and date fields
      }
    });
  }
}
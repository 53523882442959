import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["input"];
  static values = { url: String }

  connect() {
    this.search = debounce(this.search.bind(this), 300); // Debounce the search
  }

  async search() {
    const query = this.inputTarget.value;
    
    const request = new FetchRequest("get", this.urlValue+"?query="+query, { responseKind: "turbo-stream" })
    await request.perform()

  }
}

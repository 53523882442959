import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "addressInfoDisplay", "locationSelectField" ]

  connect() {
    if (this.hasLocationSelectFieldTarget && this.locationSelectFieldTarget.value) {
      this.getAddressObject(this.locationSelectFieldTarget.value)
    }
  }

  updateAddressInfo(event) {
    let deliverToId = event.target.value;  
     
    this.getAddressObject(deliverToId);
  }

  async getAddressObject(deliverToId) {
    try {
      const response = await fetch(`/locations/${deliverToId}.json`);
      const data = await response.json();
  
      // Format the address
      const addressString = this.formatAddress(data.address);
  
      // Update the display target with the formatted address
      this.addressInfoDisplayTarget.innerHTML = addressString;
    } catch (error) {
      console.error("Error fetching Location: ", error);
    }
  }

  formatAddress(address) {
    let addressString = "";
  
    // Check if each component is present before adding it to the addressString
    if (address.address1) {
      addressString += address.address1 + " ";
    }
  
    if (address.address2) {
      addressString += address.address2 + "<br />";
    } else {
      addressString += "<br />";
    }
  
    if (address.city) {
      addressString += address.city + ", ";
    }
  
    if (address.state) {
      addressString += address.state + " ";
    }
  
    if (address.zipcode) {
      addressString +=  address.zipcode;
    }
  
    return addressString;
  }

}
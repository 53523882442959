import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [ "noteModal" ]

  connect() {
    this.noteModal = Modal.getOrCreateInstance(this.noteModalTarget);
  }

  openNoteModal(event) {
    this.noteModal.show()
  }

  noteCreated(event) {
    event.preventDefault()
    let success = event.detail.success
    if(success) {
      this.noteModal.hide()
      event.target.reset()
    }
  }
}

// app/javascript/controllers/pick_list_controller.js
import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['billOfLadingModal'];
  static values = { url: String }

  connect() {
    this.billOfLadingModal = Modal.getOrCreateInstance(this.billOfLadingModalTarget);
    console.log(this.urlValue)
  }

  openBillOfLadingModal(event) {
    this.billOfLadingModal.show()
  }

  billOfLadingCreated(event) {
    event.preventDefault()
    let success = event.detail.success
    if(success) {
      this.billOfLadingModal.hide()
      event.target.reset()
      if(this.urlValue) {
        Turbo.visit(this.urlValue)
      }
      else{
        Turbo.visit(event.srcElement.action)
      }
      
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [ 'selectField' ];

  connect() {
    if (this.hasSelectFieldTarget) {
      this.enableTS();
    }
  }

  enableTS() {
    const preloadedOptions = JSON.parse(this.selectFieldTarget.dataset.preloaded || "[]");

    const tomSelect = new TomSelect(this.selectFieldTarget, {
      valueField: 'id',
      labelField: 'site_name',
      searchField: ['site_name', 'location_code'],  // Search by both site_name and location_code
      placeholder: 'Start Typing to Search',
      load: function(query, callback) {
        if (!query.length) return callback();
        fetch(`/locations/search?search=${encodeURIComponent(query)}`)
          .then(response => response.json())
          .then(json => {
            callback(json);
          }).catch(() => {
            callback();
          });
      },
      render: {
        option: function(item, escape) {
          let displayName = escape(item.site_name);
          if (item.location_code) {
            displayName += ` - ${escape(item.location_code)}`;
          }
          return `<div>${displayName}</div>`;
        },
        item: function(item, escape) {
          let displayName = escape(item.site_name);
          if (item.location_code) {
            displayName += ` - ${escape(item.location_code)}`;
          }
          return `<div>${displayName}</div>`;
        }
      }
    });

    // Add preloaded options to TomSelect
    preloadedOptions.forEach(option => {
      tomSelect.addOption(option);
      tomSelect.addItem(option.id);
    });
  }
}

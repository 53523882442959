import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
    connect() {
      this.alert_message = "Changes have not been saved.  Are you sure you want to leave?"
    }
    disconnect() {
    }
    
  }
  
  
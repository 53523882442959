// app/javascript/controllers/pick_list_controller.js
import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['checkbox', 'selectAll', 'generateButton', 'pickListModal'];

  connect() {
    this.updateSelectAllState();
    this.selectedItems = [];
    this.pickListModal = Modal.getOrCreateInstance(this.pickListModalTarget);
  }

  async openPickListModal(event) {
    event.preventDefault()
  
    const queryString = "?handling_units="+this.selectedItems.toString()

    const request = new FetchRequest("get", event.currentTarget.href + queryString, { responseKind: "turbo-stream" })
    const response = await request.perform()

    if(response.ok) {
      this.pickListModal.show()
    }
  }

  selectAllChanged() {
    const isChecked = this.selectAllTarget.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });

    this.updateSelectedItems();
  }

  itemChanged(event) {
    const isChecked = event.target.checked;

    this.updateSelectedItems();
  }

  updateSelectAllState() {
    const hasCheckboxes = this.checkboxTargets.length > 0;
    const allSelected = hasCheckboxes ? [...this.checkboxTargets].every((checkbox) => checkbox.checked) : false;
    this.selectAllTarget.checked = allSelected;
  }

  updateSelectedItems() {
    this.selectedItems = [];
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        this.selectedItems.push(checkbox.value);
      }
    });

    if (this.selectedItems.length > 0) {
      this.generateButtonTarget.classList.remove("disabled")
    }
    else{
      this.generateButtonTarget.classList.add("disabled")
    }
  }

  pickListCreated(event) {
    event.preventDefault()
    let success = event.detail.success
    if(success) {
      this.pickListModal.hide()
      event.target.reset()
      Turbo.visit(event.srcElement.action)
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [ "newModal", "editModal" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    this.newModal = Modal.getOrCreateInstance(this.newModalTarget);
    this.editModal = Modal.getOrCreateInstance(this.editModalTarget);
  }

  add_association(event) {
    event.preventDefault()
    let success = event.detail.success

    if(success) {
      this.newModal.hide()
      event.target.reset()
    }
  }

  edit_association(event) {
    this.editModal.show()
  }

  update_association(event){
    event.preventDefault()

    let success = event.detail.success

    if(success) {
      this.editModal.hide()
      event.target.reset()
    }
  }

  resetForm(event) {
      this.element.reset()
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    if (window.confirm("Are you sure?")) {
      // New records are simply removed from the page
      if (wrapper.dataset.newRecord == "true") {
        wrapper.remove()
      // Existing records are hidden and flagged for deletion
      } else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = 'none'
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [ 'selectField' ];
  connect() {
    if (this.hasSelectFieldTarget) {
      this.enableTS();
    }
  }

  disconnect() {
  }

  enableTS() {
    new TomSelect(this.selectFieldTarget, { allowEmptyOption: true });
  }
}